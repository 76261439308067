import {
    RequireRegistration, IRequireRegistrationProps,
    IRequireRegistrationAdditionalParams
} from '@@components/messages/requireregistration';
import { Entities } from '@inwink/entities/entities';
import { States } from '../services';
import { userMessageActions } from ".";
import { RequirePersonKind } from '@@components/messages/requirepersonkind';

export const messageRegistrationActions = {
    notifyRequireRegistration(elt: HTMLElement, i18nHelper: Entities.i18nHelper,
        sessionId?: string, exhibitorId?: string, tradKeyPrefix?: string) {
        return (dispatch, getState: () => States.IAppState) => {
            const registrationAdditionalParams: IRequireRegistrationAdditionalParams = {};
            if (sessionId) {
                registrationAdditionalParams.registeredsessionid = sessionId;
            }
            if (exhibitorId) {
                registrationAdditionalParams.registeredpartnerid = exhibitorId;
            }
            const state = getState();
            const rrprops: IRequireRegistrationProps = {
                i18n: state.i18n,
                configuration: state.event?.detail?.configuration || state.community?.detail?.configuration,
                registrationAdditionalParams: registrationAdditionalParams,
                // exhibitorId: exhibitorId,
                // sessionId: sessionId,
                tradKeyPrefix: tradKeyPrefix || "action.requireregistration",
                onClick: () => {
                    userMessageActions.hideMessage()(dispatch);
                }
            };
            userMessageActions.showMessage(
                i18nHelper,
                RequireRegistration, rrprops, elt
            )(dispatch);
        };
    },
    notifyRequirePersonKinds(
        elt: HTMLElement,
        i18nHelper: Entities.i18nHelper) {
        return (dispatch) => {

            return userMessageActions.showMessage(
                i18nHelper,
                RequirePersonKind, {}, elt
            )(dispatch).then((res) => {
                if (res) {
                    return Promise.resolve(true);
                } else {
                    return Promise.resolve(false);
                }
            });
        };
    }
};
