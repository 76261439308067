import * as React from 'react';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { AppLabel } from '../../services/i18nservice';
import { wrapReduxStore } from '../../store';

import './requiredaccess.less';

@withI18nHelper()
class RequirePersonKindComponent extends React.PureComponent {
    render() {
        return <div className="requiredaccess">
            <div className="message">
                <AppLabel 
                    i18n="action.requirepersonkind"
                />
            </div>
        </div>;
    }
}

export const RequirePersonKind = wrapReduxStore(RequirePersonKindComponent);