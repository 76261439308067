import { Entities } from '@inwink/entities/entities';
import { States } from '../services';
import { userMessageActions } from ".";
import { IRequireGroupMembershipProps, RequireGroupRegistration } from '@@components/messages/requiregroupregistration';

export const messageGroupRegistrationActions = {
    notifyRequireGroupRegistration(
        elt: HTMLElement,
        i18nHelper: Entities.i18nHelper,
        community: States.ICommunityState,
        groupId: string) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            const rgrprops: IRequireGroupMembershipProps = {
                i18n: state.i18n,
                community: community,
                groupId: groupId,
                onClick: () => {
                    userMessageActions.hideMessage()(dispatch);
                }
            };
            return userMessageActions.showMessage(
                i18nHelper,
                RequireGroupRegistration, rgrprops, elt
            )(dispatch).then((res) => {
                if (res) {
                    return Promise.resolve(true);
                } else {
                    return Promise.resolve(false);
                }
            });
        };
    }
};
