import * as React from 'react';
import { connect } from 'react-redux';
import * as assign from 'lodash/assign';
import { bindActionCreators } from 'redux';
import { PopoverManager, IPopoverManager } from '@inwink/modals/popovermgr';
import { i18NHelperContext } from '@inwink/i18n/reactcontext';
import { logging } from '@inwink/logging';
import { withRouter } from 'react-router-dom';
import { userMessageActions } from '../services/usermessageactions';
import { AppTextLabel } from '../services/i18nservice';
import { States } from '../services/services';
import { getVisualConfiguration } from '../data/templates';
import { NavBlocker } from './navblocker';
import { trackError, TrackingShardType } from '@@api/front/tracking.error';
import type { IRequestManager } from '@inwink/apibase/requestmanager';

interface IUserMessageProps {
    history?: any;
    location?: any;
    event: States.IEventState;
    community: States.ICommunityState;
    message?: States.IAppUserMessageState;
    userMessageActions?: typeof userMessageActions;
}

class UserMessageComponent extends React.PureComponent<IUserMessageProps, any> {
    popovermgr: IPopoverManager;

    constructor(props: IUserMessageProps) {
        super(props);
        this.state = {
            visualConfig: getVisualConfiguration(this.props.community?.data || this.props.event?.data)
        };
    }

    componentDidCatch(error, info) {
        // this.setState({ hasError: true, error: error, info });
        logging.getLogger("Rendering").error("error rendering myspace bloc", error, info);
        let loc = typeof window !== "undefined" && window.location.href;
        if (this.props.location) {
            loc = this.props.location.pathname + this.props.location.search;
        }

        let shardType: TrackingShardType;
        let shardId;
        let requestMgr: IRequestManager;
        if (this.props.event?.requestManagers?.apiFront) {
            requestMgr = this.props.event.requestManagers.apiFront;
            shardType = 'event';
            shardId = this.props.event.eventid;
        } else if (this.props.community?.requestManagers?.apiFront) {
            requestMgr = this.props.community.requestManagers.apiFront;
            shardType = 'community';
            shardId = this.props.community.communityid;
        }
        if (requestMgr) {
            trackError(requestMgr, shardType, shardId, error, "", loc);
        }
    }

    navblocker = (props, location, action) => {
        const disableOverlayDismiss = this.props.message?.currentMessage?.options?.disableOverlayDismiss;
        if (!disableOverlayDismiss) {
            props.onCompleted();
            if (action === "POP") return false;
            return true;
        }
    };

    componentDidMount() {
        this.checkMessage(null);
    }

    componentDidUpdate(prevProps: IUserMessageProps) {
        this.checkMessage(prevProps);
    }

    checkMessage(prevProps: IUserMessageProps) {
        const nextprops = this.props;
        if (nextprops.message !== prevProps?.message) {
            if (nextprops.message.currentMessage) {
                const message = nextprops.message.currentMessage;
                if (message.show && nextprops.message.currentMessage !== prevProps?.message.currentMessage) {
                    let theme = "";
                    const tmpTheme = message.props?.popovertheme
                        || this.state.visualConfig?.global?.popoverTheme
                        || "primarycolorbloc";
                    if (tmpTheme && !message?.props?.noBloctheme) {
                        theme = "bloctheme-" + tmpTheme;
                    }

                    const targetId = (nextprops.event?.eventid && ("event-" + nextprops.event.eventid))
                        || (nextprops.community?.communityid && ("community-" + nextprops.community.communityid))
                        || "ukn";

                    const popoverClassName = "appuserpopover " + targetId + " "
                        + (message.modalClassName ? message.modalClassName + " " : "")
                        + (message.className || '');

                    const component = (props) => {
                        return <i18NHelperContext.Provider value={message.i18nHelper}>
                            <div className={"appusermessage bloctheme " + theme}>
                                <NavBlocker onBlocked={(location, action) => this.navblocker(props, location, action)} />
                                {React.createElement(message.component,
                                    assign({}, message.props, { theme: tmpTheme, ...props, ...message.props }))}
                            </div>
                        </i18NHelperContext.Provider>;
                    };

                    const res = (message.popovermgr || this.popovermgr)
                        .popoverPortal(message.parentElt, component, null, popoverClassName, message.options).then((res2) => {
                            this.props.userMessageActions.hideMessage();
                            return res2;
                        });
                    if (message.promiseHandler) {
                        message.promiseHandler.setProps = (res as any).setProps;
                        res.then(message.promiseHandler.complete, message.promiseHandler.error);
                    }
                }
            }
            if (nextprops.message.currentModal) {
                const modal = nextprops.message.currentModal;
                if (modal.show && modal !== prevProps?.message?.currentModal) {
                    let theme = "";
                    const tmpTheme = (modal.props && modal.props.modaltheme) || this.state.visualConfig?.global?.modalTheme;
                    if (tmpTheme) {
                        theme = "bloctheme-" + tmpTheme;
                    }

                    const targetId = (nextprops.event?.eventid && ("event-" + nextprops.event.eventid))
                        || (nextprops.community?.communityid && ("community-" + nextprops.community.communityid))
                        || "ukn";

                    const modalClassName = "appusermodal " + targetId + " "
                        + (modal.modalClassName ? modal.modalClassName + " " : "")
                        + (modal.component ? modal.component.className || '' : '');

                    const component = (props) => {
                        let title;
                        const componentProps = assign({}, modal.props, { theme: tmpTheme, ...props, ...modal.props });
                        if (modal.noTitle != true) {
                            if (modal.title && typeof modal.title === "function") {
                                title = <h3 className="title bloc-accent">{React.createElement(modal.title, componentProps)}</h3>;
                            } else {
                                title = <AppTextLabel component="h3" className="title bloc-accent" i18n={modal.title || ""} />;
                            }
                        }
                        return <i18NHelperContext.Provider value={modal.i18nHelper}>
                            <div className={"appusermessage app-content bloctheme " + theme + (modal.noTitle ? " no-title" : "")}>
                                <NavBlocker onBlocked={(location, action) => this.navblocker(props, location, action)} />
                                <header className={"appusermessage-header bloc-defaultbg" + (modal.noTitle ? " no-title" : "")}>
                                    {title}
                                    <div className="actions">
                                        <button
                                            type="button"
                                            onClick={(arg) => {
                                                arg.preventDefault();
                                                arg.stopPropagation();
                                                props.onCompleted();
                                            }}
                                            className="btnclose bloc-darkaccentbg"
                                        >
                                            <i className="inwink-dialog-cancel" />
                                        </button>
                                    </div>
                                </header>
                                <section className="appusermessage-content">
                                    {React.createElement(modal.component, componentProps)}
                                </section>
                            </div>
                        </i18NHelperContext.Provider>;
                    };
                    const res2 = (modal.popovermgr
                        || this.popovermgr).modalPortal(component, null, modalClassName, modal.options)
                        .then((result) => {
                            this.props.userMessageActions.hideModal();
                            return result;
                        });
                    if (modal.promiseHandler) {
                        modal.promiseHandler.setProps = (res2 as any).setProps;
                        res2.then(modal.promiseHandler.complete, modal.promiseHandler.error);
                    }
                }
            }
        }
    }

    render() {
        return <div className="appusermessage-container">
            <PopoverManager ref={(mgr) => {
                this.popovermgr = mgr;
            }}
            />
        </div>;
    }
}

function mapStateToUserMessageProps(state: States.IAppState) {
    return {
        message: state.usermessage,
        event: state.event,
        community: state.community
    };
}

function mapDispatchToUserMessageProps(dispatch) {
    return {
        userMessageActions: bindActionCreators(userMessageActions, dispatch)
    };
}

export const AppUserMessage: new (any) => React.Component<IUserMessageProps, any> = connect(
    mapStateToUserMessageProps,
    mapDispatchToUserMessageProps
)(withRouter(UserMessageComponent as any) as any) as any;
