import {
    ChallengeActionInterceptor,
    ChallengeProvider,
    IRequiredLoginBaseOptions,
    IRequireLoginBaseComponentProps,
    RequireLoginBase
} from '@@components/messages/requirelogin.base';
import { eventUserBootstrapModule, communityUserBootstrapModule } from '@@routes/appmodules';
// import { registrationInfo as eventRegistrationInfo } from "@@event/api/person";
import { registrationInfo as communityRegistrationInfo } from "@@community/api/member";
import { Entities } from '@inwink/entities/entities';
import { States } from '../services';
import { userMessageActions } from './index';
import React from 'react';

export const loginActions = {
    notifyRequireLogin(elt: HTMLElement, i18nHelper: Entities.i18nHelper, options?: IRequiredLoginBaseOptions,
        challengeActionInterceptor?: ChallengeActionInterceptor) {
        return (dispatch, getState: () => States.IAppState) => {
            if ((global as any).disableAuthenticatedFeatures) {
                return;
            }

            const appstate = getState();
            let challengeProvider: ChallengeProvider;
            // TODO Désactiver ça quand on a envie d'activer le challenge mode sur event
            // if (appstate.event?.eventid) {
            //     challengeProvider = (email) => {
            //         return eventRegistrationInfo(appstate.event.requestManagers, email).then((res) => {
            //             return { exist: res.isRegistered };
            //         });
            //     };
            // }

            if (appstate.community?.communityid) {
                challengeProvider = (email) => {
                    return communityRegistrationInfo(appstate.community.requestManagers.apiFront, email).then((res) => {
                        return { exist: res.isMember };
                    });
                };
            }

            const state = getState();
            const rlProps: IRequireLoginBaseComponentProps = {
                // user: state.user,
                i18n: state.i18n,
                options: options,
                configuration: state.event?.detail?.configuration || state.community?.detail?.configuration,
                onShowLogin: (email) => {
                    return loginActions.showLogin(null, null, email)(dispatch, getState);
                },
                // onClick: () => {
                //     // userMessageActions.hideMessage()(dispatch);
                // },
                challengeProvider: challengeProvider,
                challengeActionInterceptor: challengeActionInterceptor
            };
            return userMessageActions.showMessage(
                i18nHelper,
                (msgProps: IRequireLoginBaseComponentProps & { onCompleted: () => void }) => {
                    return <RequireLoginBase {...msgProps} onClick={() => {
                        // msgProps.onClick(action);
                        msgProps.onCompleted();
                    }} />;
                }, rlProps, elt
            )(dispatch);
        };
    },

    showLogin(afterAuth?, customRedirect?: string, email?: string) {
        return (dispatch, getState: () => States.IAppState) => {
            const appstate = getState();

            if (appstate.event?.eventid) {
                let tenantId = appstate.event && appstate.event.detail && appstate.event.detail.tenantId;
                let event = appstate.event && appstate.event.tinyUrl;
                if (__DEVMODE__) {
                    event = null;
                }

                if (!tenantId) {
                    tenantId = appstate.rootwebsite && appstate.rootwebsite.detail && appstate.rootwebsite.detail.authTenantId;
                }

                // if (!oidcmgr) {
                //     return Promise.reject({ message: "no oidc mgr" });
                // }

                return appstate.event.requestManagers.userActions.signIn({
                    lang: appstate.i18n.currentLanguageCode,
                    tenant: tenantId,
                    redirectUrlAlias: event,
                    customRedirect: customRedirect,
                    authinfo: email ? { email: email } : null
                }).then(() => {
                    return eventUserBootstrapModule().then((mod) => {
                        return mod.currentUserActions.checkCurrentUser()(dispatch, getState);
                    });
                }).then(() => {
                    if (afterAuth) {
                        afterAuth();
                    }
                }).catch((error) => {
                    console.error("sigin error", error);
                    if (afterAuth) {
                        afterAuth();
                    }
                });
            }

            if (appstate.community?.communityid) {
                let community = appstate.community.tinyUrl;
                if (__DEVMODE__) {
                    community = null;
                }
                return appstate.community.requestManagers.userActions.signIn({
                    lang: appstate.i18n.currentLanguageCode,
                    tenant: appstate.community.tenantid,
                    redirectUrlAlias: community,
                    customRedirect: customRedirect,
                    authinfo: email ? { email: email } : null
                }).then(() => {
                    return communityUserBootstrapModule().then((mod) => {
                        mod.currentUserActions.checkCurrentUser()(dispatch, getState);
                    });
                }).then(() => {
                    if (afterAuth) {
                        afterAuth();
                    }
                }).catch((error) => {
                    console.error("sigin error", error);
                    if (afterAuth) {
                        afterAuth();
                    }
                });
            }

            return Promise.reject(new Error("no valid scope for login"));
        };
    }
};
