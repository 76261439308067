import type { ICommunityRequestManager, ICommunityRequests } from "@@services/apiaccessprovider.definition";
import { defaultPostHeaderForJsonData } from "@@api/index";
import type { IInwinkEntityV3QueryOptions } from "@@api/front";

export function createMember(
    requests: ICommunityRequests,
    data: any,
    dataInfo?: string,
    sign?: string,
    contentTemplateId?: string,
    blocId?: string
): Promise<{ id: string; error?: string }> {
    const d = data;
    let url = `community/${requests.apiFront.communityId}/member/register`;
    const params = [];
    if (dataInfo) { params.push('data-info=' + dataInfo); }
    if (sign) { params.push('sign=' + sign); }
    if (contentTemplateId) { params.push('contentTemplateId=' + contentTemplateId); }
    if (blocId) { params.push('blocId=' + blocId); }
    if (params.length) { url = url + '?' + params.join("&"); }
    return requests.apiFront.postJson(
        url,
        JSON.stringify(d),
        defaultPostHeaderForJsonData
    );
}

export interface ISubscription {
    id: string;
    orderItemId: string;
    entityLinkedId: string;
    isActive: boolean;
}

export function checkMailAlreadyExist(requestManager: ICommunityRequestManager, mail: string): Promise<boolean> {
    const url = `community/${requestManager.communityId}/member/mailalreadyexist?targetemail=${encodeURIComponent(mail)}`;
    return requestManager.getJson(url, defaultPostHeaderForJsonData);
}

export function registrationInfo(requestManager: ICommunityRequestManager, email: string): Promise<{ isMember: boolean }> {
    const url = `community/${requestManager.communityId}/member/registration-info?targetemail=${encodeURIComponent(email)}`;
    return requestManager.postJson(url, "{}", defaultPostHeaderForJsonData);
}

export function memberSubscriptions(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
): Promise<ISubscription[]> {
    const url = `community/${requestManager.communityId}/member/subscriptions`;
    return requestManager.postJson<any>(url, options && JSON.stringify(options), defaultPostHeaderForJsonData);
}

export interface IMemberSubscriptionCancel {
    orderItemServiceId: string
}

export function memberSubscriptionCancel(
    requestManager: ICommunityRequestManager,
    options?: IMemberSubscriptionCancel
) {
    const url = `community/${requestManager.communityId}/member/subscription/cancel`;
    return requestManager.postJson<any>(url, options && JSON.stringify(options), defaultPostHeaderForJsonData);
}