import { registerMember } from '@@modules/community/api/groups';
import { IInwinkStore, wrapReduxStore } from '@@store/index';
import { AsyncButton } from '@inwink/buttons';
import { AppTextLabel } from '@inwink/i18n';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { AppLabel } from '../../services/i18nservice';
import { States } from '../../services/services';

import './requiredaccess.less';

export interface IRequireGroupMembershipProps {
    store?: IInwinkStore;
    community?: States.ICommunityState;
    i18n?: States.i18nState;
    onClick?: () => void;
    groupId?: string;
    onCompleted?: (res?: any) => void;
}

class RequireGroupRegistrationComponent extends React.Component<IRequireGroupMembershipProps, any> {
    onClick = () => {
        return registerMember(
            this.props.community.requestManagers.apiFront, this.props.groupId, null
        ).then((res: any) => {
            this.props.store.dispatch({
                type: "USER_GROUPMEMBER_CHANGED",
                payload: {
                    groupMembers: [
                        ...this.props.store.getState().user.currentUser.member.groupMembers,
                        {
                            group: {
                                id: this.props.groupId
                            },
                            status: res.status
                        }
                    ],
                }
            });
            this.props.onCompleted(true);
        }, (err) => {
            console.error("error while registrating", err);
        });
    };

    renderGroupRegistrationActions() {
        let btn;
        if (this.props.groupId) {
            btn = <AsyncButton
                className="btnregistration checked"
                onClick={this.onClick}
            >
                <AppTextLabel
                    i18n="community.content.group.registration.button.unregistered"
                />
            </AsyncButton>;
        }
        if (btn) {
            return <div className="actions">
                {btn}
            </div>;
        }

        return null;
    }

    render() {
        return <div className="requiredaccess">
            <div className="message">
                <AppLabel i18n="action.community.requiregroupregistration" />
            </div>
            {this.renderGroupRegistrationActions()}
        </div>;
    }
}

export const RequireGroupRegistration: new (props: IRequireGroupMembershipProps)
=> React.Component<IRequireGroupMembershipProps, any> = 
withRouter(wrapReduxStore(RequireGroupRegistrationComponent) as any) as any;
