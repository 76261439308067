import * as React from 'react';
import * as History from 'history';
import {withRouter} from 'react-router-dom';

export interface NavBlockerProps {
    history?: History.History; 
    onBlocked: (nextLocation: History.Location, action: string, history: History.History, unblock: () => void) => boolean
}

class NavBlockerCtrl extends React.Component<NavBlockerProps, any> {
    unblock: History.UnregisterCallback; 

    constructor(props: NavBlockerProps){
        super(props);
    }

    blockLocation = (nextLocation: History.Location, action : string) => {
        var nextloc = nextLocation;
        return this.props.onBlocked(nextLocation, action, this.props.history, this.unblock);
    }

    componentDidMount(){
        this.unblock = this.props.history.block(this.blockLocation as any);
    }

    componentWillUnmount(){    
        if (this.unblock){
            this.unblock();
            this.unblock = null;
        }
    }

    render(){
        return null;
    }    
}

export var NavBlocker : new (any) => React.Component<NavBlockerProps, any> = withRouter(NavBlockerCtrl as any) as any;